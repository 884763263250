<template>
    <div class="home">
        <header>
          <h1 class="logo-tit">学豆教育</h1>
        </header>
        <div class="ban">
            <swiper :options="swiperOption" ref="mySwiper">
                <!-- slides -->
                <swiper-slide class="ban-item">
                    <img src="@/assets/img/ban1.png" width="100%" />
                </swiper-slide>
                <swiper-slide class="ban-item">
                    <img src="@/assets/img/ban2.jpg" width="100%" />
                </swiper-slide>
                <!-- Optional controls -->
                <div class="swiper-pagination"  slot="pagination"></div>
            </swiper>
        </div>
        <div class="about">
            <dl class="item about-us">
                <dt>
                    <h2>关于我们</h2>
                    <p class="sub-tit">ABOUT US</p>
                </dt>
                <dd class="desc">
                    学豆教育成立于2019年，希望通过正规的课程、系统的培训帮助越来越多的人找到创业的方向，实现个人的价值。目前，学豆教育以电商创业培训为中心，同步拓展互联网创业培训业务、快消行业创业培训业务等范畴，为广大用户提供一站式创业培训项目及解决方案。未来，学豆教育将和众多学员一起，不忘初心，砥砺前行。
                </dd>
            </dl>
            <section class="item about-img">
                <img src="@/assets/img/about.webp"  width="100%" />
            </section>
        </div>
        <section class="team">
            <dl class="header">
                <dt>讲师介绍</dt>
                <dd>Presentation of lecturer</dd>
            </dl>
            <ul class="list">
                <li class="item">
                    <div class="img">
                        <img src="@/assets/img/team1.webp" />
                    </div>
                    <div class="cont">
                        <dl>
                            <dt>王煜杨</dt>
                            <dd>新型电商实战导师</dd>
                        </dl>
                        <div class="desc">
                            多年直销行业团队建设和管理经验，擅长社群运营课程体系搭建，用户心理建设、提高用户粘性
                        </div>
                    </div>
                </li>
                <li class="item">
                    <div class="img">
                        <img src="@/assets/img/team2.webp" />
                    </div>
                    <div class="cont">
                        <dl>
                            <dt>薛哲</dt>
                            <dd>店铺管理专家</dd>
                        </dl>
                        <div class="desc">
                            5年互联网营销/终端店铺管理经验<br/>
                            擅长社群裂变<br/>
                            指导0社交卖货技术用户月销售过万
                        </div>
                    </div>
                </li>
            </ul>
        </section>
        <section class="service">
            <dl class="header">
                <dt>服务项目</dt>
                <dd>Service Item</dd>
            </dl>
            <ul class="list">
                <li class="item">①  学员资质审核</li>
                <li class="item">②   学员创业项目预审</li>
                <li class="item">③  导师创业辅导</li>
            </ul>
            <div class="banner">
                <img src="@/assets/img/banner.webp" width="100%" />
            </div>
        </section>
        <footer>
            <h2>宁波学豆教育科技有限公司</h2>
            <div class="main">
                <div class="cont">
                    <p>学豆教育成立于2019年，希望通过正规的课程、系统的培训帮助越来越多的人找到创业的方向，实现个人的价值。</p>
                    <p>未来，学豆教育和众多学员一起，不忘初心，砥砺前行。</p>
                </div>
                <div class="contact">
                    <section class="item">
                        <p>联系邮箱：1500581904@qq.com</p>
                        <p>联系地址：浙江省宁波市海曙区中宪巷19号三楼115</p>
                    </section>
                    <section class="item">
                        <p>联系QQ：1500581904</p>
                        <p>联系电话：18616837493</p>
                    </section>
                </div>
            </div>
            <div class="copy-right">
                &copy;学豆教育官网版权所有
            </div>
        </footer>
    </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    name: "Home",
    data () {
        return {
            swiperOption: {
                autoplay:true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                },
            }
        }
    },
    components: {
        swiper,
        swiperSlide
    }
};
</script>

<style scoped lang="scss">
    @import './home.scss';
</style>  
